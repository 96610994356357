<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14.994 10">
    <path
      :style="iconStyle"
      d="M13.5,5.817a.681.681,0,0,0-.005.958L16.66,9.947H4.609a.677.677,0,0,0,0,1.354H16.655l-3.166,3.172a.685.685,0,0,0,.005.958.674.674,0,0,0,.953-.005L18.738,11.1h0a.76.76,0,0,0,.141-.214.646.646,0,0,0,.052-.26.679.679,0,0,0-.193-.474L14.447,5.833A.663.663,0,0,0,13.5,5.817Z"
      transform="translate(-3.938 -5.626)"
    />
  </svg>
</template>

<script>
export default {
  // TODO: This icon is not used anywhere
  props: {
    height: {
      type: String,
      default: '10',
    },
    width: {
      type: String,
      default: '14.994',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
